<template>
  <article>
    <Hero heading="You have successfully unsubscribed from our email list." :subheading="true">
      <template #subheading>
        <p>Electrify America will no longer send you this type of email communication.</p>
        <p>
          Did you unsubscribe in error? <router-link class="link" :to="{ name: 'sign-up-en' }">Subscribe.</router-link>
        </p>
      </template>
    </Hero>
  </article>
</template>

<script>
import Hero from '../components/Hero/Hero.vue';

export default {
  name: 'unsubscribe',
  metaInfo: {
    title: 'Unsubscribe | Electrify America',
    meta: [
      {
        name: 'description',
        content: 'You have successfully unsubscribed from our email list.',
      },
      { name: 'robots', content: 'noindex' },
    ],
  },
  components: { Hero },
};
</script>
